"use strict";

module.exports = {

  dateFormat(x, completo, soNumeros, dd_mm_yyyy, padraoUTC, origemPadraoEN) {
    padraoUTC = padraoUTC ? padraoUTC : false;
    origemPadraoEN = origemPadraoEN ? origemPadraoEN : false; //se data está no padrão Americano (MM/DD/YYYY) ajusta
    if (!x) return null;
    if (!(x instanceof Date)) {
      //se String e não date... converte
      let dateParts = x.split("/");
      if (dateParts.length === 1) {
        dateParts = x.split("-");
        if (dateParts.length === 1) {
          return null; //erro no formato de data de entrada: nem "-" nem "/" na formatação
        }
      }
      //se AAAA-MM-DD
      if (Number(dateParts[0]) > Number(dateParts[2])) {
        if (origemPadraoEN) {
          x = new Date(
            Number(dateParts[0]),
            Number(dateParts[2]) - 1,
            Number(dateParts[1])
          );
        } else {
          x = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
          );
        }
      } else {
        if (origemPadraoEN) {
          x = new Date(
            Number(dateParts[2]),
            Number(dateParts[0]) - 1,
            Number(dateParts[1])
          );
        } else {
          x = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
        }
      }
    }
    let ret = "";
    let y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    let ho = x.getHours().toString();
    let mi = x.getMinutes().toString();
    let se = x.getSeconds().toString();

    d.length == 1 && (d = "0" + d);
    m.length == 1 && (m = "0" + m);
    ho.length == 1 && (ho = "0" + ho);
    mi.length == 1 && (mi = "0" + mi);
    se.length == 1 && (se = "0" + se);

    if (soNumeros) {
      ret = y + m + d;
      if (completo) {
        ret += ho + mi + se;
      }
    } else {
      if (dd_mm_yyyy) {
        ret = d + "/" + m + "/" + y;
      } else {
        ret = y + "-" + m + "-" + d;
      }
      if (completo || padraoUTC) {
        ret += (padraoUTC ? "T" : " ") + ho + ":" + mi + ":" + se;
      }
    }

    return ret;
  },

  diffDatasEmHoras(dataMenor, dataMaior) {
    dataMenor = this.arruma_data(dataMenor);
    dataMaior = this.arruma_data(dataMaior);
    const diff = dataMaior.getTime() - dataMenor.getTime(); // Subtrai uma data pela outra
    const horas = Math.trunc(diff / (1000 * 60 * 60)); // Divide o total pelo total de milisegundos correspondentes a 1 hora.
    return horas;
  },

  diffDatasEmDias(dataMenor, dataMaior) {
    dataMenor = this.arruma_data(dataMenor);
    dataMaior = this.arruma_data(dataMaior);
    const diff = dataMaior.getTime() - dataMenor.getTime(); // Subtrai uma data pela outra
    const days = Math.trunc(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
    return days;
  },

  diffDatasEmMeses(dataMenor, dataMaior) {
    dataMenor = this.arruma_data(dataMenor);
    dataMaior = this.arruma_data(dataMaior);
    let diminuiMes = dataMenor.getDate() > dataMaior.getDate() ? 1 : 0;
    let months;
    months = (dataMaior.getFullYear() - dataMenor.getFullYear()) * 12;
    months -= dataMenor.getMonth();
    months -= diminuiMes;
    months += dataMaior.getMonth();
    return months <= 0 ? 0 : months;
  },

  diffDatasEmAnos(dataMenor, dataMaior) {
    dataMenor = this.arruma_data(dataMenor);
    dataMaior = this.arruma_data(dataMaior);
    let anos = dataMaior.getFullYear() - dataMenor.getFullYear();
    if (dataMaior.getMonth() < dataMenor.getMonth()) {
      //se não completou aniversário
      anos--;
    }
    return anos <= 0 ? 0 : anos;
  },

  idade_hoje(nascimento) {
    let hoje = new Date();
    var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
    if (
      new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
      new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate())
    )
      diferencaAnos--;
    return diferencaAnos;
  },

  arruma_data(data) {
    let hora = "";
    if (!data) {
      return;
    }
    if (!(data instanceof Date)) {
      if (data.length > 11 && data.indexOf(":") > 0) {
        //se tem hora na data...
        hora = data.substring(data.indexOf(":") - 2);
        data = data.substring(0, data.indexOf(":") - 3);
      }
      let aData = data.split("/");
      if (aData.length <= 1) {
        aData = data.split("-");
      }
      let ano, mes, dia;
      if (Number(aData[0]) > Number(aData[2])) {
        //entrou data no formato "AAAA-MM-DD"
        ano = aData[0];
        mes = aData[1];
        dia = aData[2];
      } else {
        ano = aData[2];
        mes = aData[1];
        dia = aData[0];
      }
      if (hora !== "") {
        let aHora = hora.split(":");
        data = new Date(
          Number(ano),
          Number(mes) - 1,
          Number(dia),
          aHora[0] ? Number(aHora[0]) : 0,
          aHora[1] ? Number(aHora[1]) : 0,
          aHora[2] ? Number(aHora[2]) : 0
        );
      } else {
        data = new Date(Number(ano), Number(mes) - 1, Number(dia));
      }
    }
    return data;
  },

  copyToClipboard(text, isIos) {
    var textArea, range, selection;

    textArea = document.createElement("textArea");
    textArea.value = text;
    document.body.appendChild(textArea);

    if (isIos) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.focus();
      textArea.select();
    }
    var success = document.execCommand("copy");
    document.body.removeChild(textArea);

    return success;
  },

  calculaIdadeApos(dataNasc, dataAdesao, idade) {
    let idadeNaAdesao = this.diffDatasEmAnos(dataNasc, dataAdesao)
    let idadeApos = 65
    if (idadeNaAdesao > 55) {
      idadeApos = idadeNaAdesao + 10
    }
    if (idade > idadeApos) {
      idadeApos = idade
    }
    return idadeApos
  },

  valor_to_string_formatado(num, casasDecimais, incluiCifrao, fromWeb) {
    let numFormatado = parseFloat(parseFloat(num).toFixed(casasDecimais)) //arruma qqr forma que entrar
    if (incluiCifrao) {
      numFormatado = numFormatado.toLocaleString('pt-BR', {minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais, style: 'currency', currency: 'BRL'});
    } else {
      numFormatado = numFormatado.toLocaleString('pt-BR', {minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais});
    }
    if (!fromWeb) {
      numFormatado = numFormatado.replace(/,/g,'#')
      numFormatado = numFormatado.replace('.',',')
      numFormatado = numFormatado.replace(/#/g,'.')
    }
    return numFormatado
  },

  perfilIdade(nascimento) {
    let idade = 0
    if (nascimento) {
        idade = this.diffDatasEmAnos(nascimento, new Date())
    } else {
        idade = 17 //força para ter o avatar mais jovem caso não identifique o nascimento/idade
    }

    //0-12  13-17 18+
    if (idade <= 12) {
        return 'kids'
    } else if (idade <= 17) {
        return 'young'
    } else {
        return 'adult'
    }
  },

  compareJSON(obj1, obj2) {
    var ret = [];
    for (var i in obj2) {
      let content1 = JSON.stringify(obj2[i] || "").replace(/ /g, "");
      let content2 = obj1
        ? JSON.stringify(obj1[i] || "").replace(/ /g, "")
        : "";
      if (content1 !== content2) {
        ret[i] = [obj2[i], obj1 ? obj1[i] : ""];
      }
    }
    return ret;
  },

  analisaAlteracoesCadastro(cadastroAntes, cadastro) {
    let aAntes = [];
    let aDepois = [];
    let aDepoisJson = []
    let alteracoesSoProfissaoRenda = true
    for (let key in cadastro) {
      //console.log('====> cadastro[key]', cadastro[key])
      let comp = this.compareJSON(cadastroAntes[key], cadastro[key]);
      if (Object.keys(comp).length > 0) {
        Object.keys(comp).forEach(item => {
          //não compara profissão porque não precisa abrir card no Ares para isso
          //não compara renda_mensal porque não precisa abrir card no Ares para isso
          if (item !== "profissao" && item !== "renda_mensal") {
            alteracoesSoProfissaoRenda = false
          }
          let vAntes = comp[item][1] ? comp[item][1] : "(vazio)";
          if (typeof vAntes === "object") {
            let vAntesString = ''
            Object.keys(vAntes).forEach((key) => {
              vAntesString += `${key}: ${vAntes[key] || ''} - `
            })
            //vAntes = JSON.stringify(vAntes);
            vAntes = vAntesString
          }
          let vDepois = comp[item][0] ? comp[item][0] : "(vazio)";
          if (typeof vDepois === "object") {
            let vDepoisString = ''
            Object.keys(vDepois).forEach((key) => {
              vDepoisString += `${key}: ${vDepois[key] || ''} - `
            })
            //vDepois = JSON.stringify(vDepois);
            vDepois = vDepoisString
          }
          aAntes.push(`${key} ==> ${item}: ${vAntes}\n`);
          aDepois.push(`${key} ==> ${item}: ${vDepois}\n`);
          aDepoisJson.push({
            campo:`${key} ==> ${item}`,
            valor: vDepois
          })
        });
      }
    }
    return {
      alterarAres: !alteracoesSoProfissaoRenda,
      arrayAlteracao: [aAntes, aDepois, aDepoisJson]
    }
  },

  analisaAlteracoesBeneficiarios(
    beneficiariosAnterior,
    beneficiarios
  ) {
    return (
      JSON.stringify(beneficiariosAnterior) === JSON.stringify(beneficiarios)
    );
  },

  retiraAcentuacao(texto) {
    texto = texto.replace(/[ÀÁÂÃÄÅ]/g, "A");
    texto = texto.replace(/[àáâãäå]/g, "a");
    texto = texto.replace(/[ÉÈÊË]/g, "E");
    texto = texto.replace(/[éèêë]/g, "e");
    texto = texto.replace(/[ÍÌÎÏ]/g, "I");
    texto = texto.replace(/[íìîï]/g, "i");
    texto = texto.replace(/[ÓÒÕÔÖ]/g, "O");
    texto = texto.replace(/[óòõôö]/g, "o");
    texto = texto.replace(/[ÚÙÛÜ]/g, "U");
    texto = texto.replace(/[úùûü]/g, "u");
    texto = texto.replace(/[Ç]/g, "C");
    texto = texto.replace(/[ç]/g, "c");
    return texto;
  },

  comparerForQueue(velho, novo, caminho, subKey) {
    const keys = Object.keys(novo);
    let atualizacao = {};
    keys.forEach(key => {
      if (typeof novo[key] === "object") {
        let atualizacaoInterna = this.comparerForQueue(velho[key], novo[key] || '', caminho, true)
        if (Object.keys(atualizacaoInterna).length > 0) {
          atualizacao[`${caminho}.${key}`] = atualizacaoInterna
        }
      } else if (!velho || velho[key] === undefined || velho[key] !== novo[key]) {
        if (subKey) {
          atualizacao[key] = novo[key];
        } else {
          atualizacao[`${caminho}.${key}`] = novo[key];
        }

      }
    });

    return atualizacao;
  },

  validaNumeroCartaoCredito(value) {
    // remove all non digit characters
    var value = value.replace(/\D/g, '');
    var sum = 0;
    var shouldDouble = false;
    // loop through values starting at the rightmost side
    for (var i = value.length - 1; i >= 0; i--) {
      var digit = parseInt(value.charAt(i));

      if (shouldDouble) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return (sum % 10) == 0;
  },

  validaValidadeCartaoCredito(value) {
    // Check 2-2 digits
    if (!/\d\d-\d\d/.test(value)) {
      return 'Formato da data de validade precisa ser MM-AA'
    }

    // Check month is 1 to 12 inclusive
    var b = value.split('-');
    if (b[0]<1 || b[0]>12) {
      return 'O mês da validade precisa estar entre 1 e 12' + b[0];
    }

    // Check is this month or later
    var d = new Date()
    var c = d.getFullYear()/100 | 0 + '';
    if (new Date(c + b[1], b[0], 1) <= d) {
      return 'Data de validade precisa superior ao mês atual'
    }

    return null;
  },

  retornaNomeMes(valor) {

    const meses = [
      {numero: '01', nome: 'Janeiro'},
      {numero: '02', nome: 'Fevereiro'},
      {numero: '03', nome: 'Março'},
      {numero: '04', nome: 'Abril'},
      {numero: '05', nome: 'Maio'},
      {numero: '06', nome: 'Junho'},
      {numero: '07', nome: 'Julho'},
      {numero: '08', nome: 'Agosto'},
      {numero: '09', nome: 'Setembro'},
      {numero: '10', nome: 'Outubro'},
      {numero: '11', nome: 'Novembro'},
      {numero: '12', nome: 'Dezembro'},
    ]

    let consulta = meses.find(mes => mes.numero === valor)

    return consulta.nome

  },

  convertDateStringtoISOString(data) {
    if(data.includes('T')) {
      let dataSplit = data.split('T')
      let dataArray = dataSplit[0].split('-')
      return new Date(dataArray[0], dataArray[1] - 1, dataArray[2]).toISOString()
    } else if(data.includes('/') && data.length === 10) {
      let dataSplit = data.split('/')
      return new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]).toISOString()
    }
  },

  valorFormatoDesc(num, fromWeb) {
		let ret = ''
		fromWeb = fromWeb || false
		if (Math.abs(num) > 999999) {
			ret = this.valor_to_string_formatado(Math.sign(num) * ((Math.abs(num) / 1000000)), 1, true, fromWeb)
			if (ret.indexOf(',0') >= 0) { //se o final do valor for ",0" retira...
				ret = this.valor_to_string_formatado(Math.sign(num) * ((Math.abs(num) / 1000000)), 0, true, fromWeb)
			}
			ret = ret + ' Mi'
		} else if (Math.abs(num) > 999) {
			ret = this.valor_to_string_formatado(Math.sign(num) * ((Math.abs(num) / 1000)), 1, true, fromWeb)
			if (ret.indexOf(',0') >= 0) { //se o final do valor for ",0" retira...
				ret = this.valor_to_string_formatado(Math.sign(num) * ((Math.abs(num) / 1000)), 0, true, fromWeb)
			}
			ret = ret + ' mil'
		} else {
			ret = this.valor_to_string_formatado(num, 2, true, fromWeb)
		}
		return ret
	}

};
