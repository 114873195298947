'use strict'

export const contratacao = {
    RENDA: 'Contribuição mensal',
    RENDA_2: 'Contribuição adicional mensal',
    SEGURO: 'Seguro',
    EMPRESTIMO: 'Empréstimo',
    APORTE: 'Aporte',
    RESGATE: 'Resgate',
    CASHBACK: 'Cashback',
    BENEFICIO: 'Benefício'
}

export const statusContratacao = {
    SOLICITADO: 'Solicitado',
    EFETIVADA: 'Efetivada',
    CANCELADO: 'Cancelado pelo usuário',
    CANCELADO_OPERADOR: 'Cancelado pelo operador',
    EMANDAMENTO: 'Em andamento'
}

export const perfilInvestimento = {
    CONSERVADOR: 'Conservador',
    MODERADO: 'Moderado',
    AGRESSIVO: 'Agressivo'
}

export const limite = {
    EMPRESTIMO: 469000
}

export const tiposSolicitacao = {
    "adesao": "Solicitação de Adesão",
    "Seguro": "Contratação de Risco",
    "Seguro - Cancelamento": "Cancelamento de Risco",
    "Contribuição mensal": "Alteração de Contribuição",
    "Contribuição adicional mensal": "Alteração de Contribuição",
    "cadastro": "Alteração de Cadastro",
    "boleto": "Segunda-via de Boletos",
    "beneficiario": "Alteração de Beneficiários",
    "Empréstimo": "Solicitação de Empréstimo",
    "Resgate": "Solicitações de Resgate",
    "Consultoria": "Solicitações de Consultoria",
    "Aporte": "Aporte extraordinário",
    "Cashback": "Cashback",
    "Benefício": "Benefício",
}

export const nivelCampanha = {
    VISUALIZACAO:'data_visualizacao',
    CLICK:'data_click',
    CONTRATACAO:'data_contratacao'
}

export const linkCampanha = {
    CADASTRO:'cadastro',
    EMPRESTIMO:'simulador-emprestimo',
    RENDA:'simulador-renda',
    SEGURO:'simulador-seguro',
    APORTE: 'simulador-aporte'
}

export default class Enum {

}
