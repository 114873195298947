'use strict';

import $ from 'jquery';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Router from './Router';
import page from 'page';
import FirebaseHelper from './FirebaseHelper';
import PrimeiroLogin from './PrimeiroLogin';


/**
 * Handles the user auth flows and updating the UI depending on the auth state.
 */
export default class Auth {
  /**
   * Returns a Promise that completes when auth is ready.
   * @return Promise
   */
  get waitForAuth() {
    return this._waitForAuthPromiseResolver.promise();
  }

  /**
   * Initializes Friendly Pix's auth.
   * Binds the auth related UI components and handles the auth flow.
   * @constructor
   */
  constructor() {
    // Firebase SDK
    //firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    this.auth = firebase.auth()
    this.auth.languageCode = 'pt-BR';
    this._waitForAuthPromiseResolver = new $.Deferred();
    this.firebaseHelper = new FirebaseHelper();
    this.primeiroLogin = new PrimeiroLogin(this.firebaseHelper);

    // Pointers to DOM Elements
    const signedInUserContainer = $('.fp-signed-in-user-container');
    this.usernameLink = $('.fp-usernamelink');
    this.preConsentCheckbox = $('#fp-pre-consent');
    this.confirmDadosButton = document.querySelector('.fp-confirm-dados')
    this.cancelarDadosButton = document.querySelector('.fp-cancelar-dados')
    this.confirmVoltarButton = document.querySelector('.fp-confirm-voltar')
    this.avisoValidacaoButton = document.querySelector('.fp-aviso-validacao')
    this.avisoValidacaoWhatsButton = document.querySelector('.fp-aviso-whatsApp')
    this.confirmDadosButton2 = document.querySelector('.fp-confirm-dados2')   
    this.base_spinner = document.querySelector('#base_spinner')
    this.base_spinner_Whats = document.querySelector('#base_spinner_Whats')

    // Configure Firebase UI.
    this.configureFirebaseUi();

    // Event bindings
    this.preConsentCheckbox.change(() => {
      const checked = this.preConsentCheckbox.is(':checked');
      const IDPButtons = $('.firebaseui-idp-button');
      if (checked) {
        IDPButtons.removeAttr('disabled');
      } else {
        IDPButtons.attr('disabled', 'disabled');
      }
    });
    this.auth.onAuthStateChanged((user) => this.onAuthStateChanged(user));
    this.avisoValidacaoButton.addEventListener('click', async () => {
      //if (! await this.firebaseHelper.enviarEmailLinkValidacao('firebase')) {
      let nome = sessionStorage.nome && sessionStorage.nome !== '' ? sessionStorage.nome : ''
      if (! await this.firebaseHelper.enviarEmailLinkValidacao('proprio', sessionStorage.emailCadastro, nome)) {
        page('/erro')  
      } else {
        page('/aviso-validacao')  
      }
      
    })
    this.confirmDadosButton.addEventListener('click', e => {      
      let celular = document.querySelector('#celular').value
      let emailAlternativo = document.querySelector('#email').value      
      this.primeiroLogin.confirmEmailFone(celular, emailAlternativo);
      this.confirmaCelular(celular);
    });
    this.avisoValidacaoWhatsButton.addEventListener('click', e => {      
      let celular = document.querySelector('#celular').value
      let emailAlternativo = document.querySelector('#email').value      
       const retorno =  this.primeiroLogin.enviarEmailLinkValidacaoWhats(celular, emailAlternativo);
       this.base_spinner_Whats.style.display = 'block'
       retorno.then( rest => {
        this.base_spinner_Whats.style.display = 'none'
        //console.log("e-mail", rest);
       })
    
    });

    this.cancelarDadosButton.addEventListener('click', e => {
      this.firebaseHelper.signOut()
    });
    this.confirmVoltarButton.addEventListener('click', e => {
      page('/primeiro-login')
    });
    this.confirmDadosButton2.addEventListener('click', e => {
      let cpf = $('.fp-input-cpf').val()
      this.primeiroLogin.confirmDados(cpf);
    });
  }  

  confirmaCelular(celular){
    const num = document.querySelector('.falha-numero');
    const numero = document.getElementById('numero');
    const retorno =  this.primeiroLogin.confirmaCelular(celular)

    retorno.then( rest => {
          numero.textContent = rest.numero;
          num.style.display = 'block';
        
    })
  }

  configureFirebaseUi() {
    // Confgiure and add the FirebaseUI Widget
    let signInFlow = 'redirect';
    // For iOS full screen apps we use the redirect auth mode.
    /*//console.log(`'standalone' in window.navigator `, 'standalone' in window.navigator)
    if (('standalone' in window.navigator)
        && window.navigator.standalone) {
      signInFlow = 'redirect';
    }*/

    // FirebaseUI config.
    //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    this.uiConfig = {
      'signInSuccessUrl': '/',
      'signInFlow': signInFlow,
      'signInOptions': [
        /*{
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account' //força sempre pedir o usuário do Google na entrada/login
          }
        },*/        
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        /*{provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'BR',
          recaptchaParameters: {
            type: 'image', // 'audio'
            size: 'invisible', // 'invisible' or 'compact'
            badge: 'bottomright' //' bottomright' or 'inline' applies to invisible.
          },
        }*/
      ],
      'callbacks': {
        'uiShown': function() {
          //const IDPButtons = $('.firebaseui-idp-button');           
          //IDPButtons.attr('disabled', 'disabled');

          /*const intervalId = setInterval(() => {
            const IDPButtons = $('.firebaseui-idp-button');           
            const nbIDPButtonDisplayed = IDPButtons.length;
            if (nbIDPButtonDisplayed > 0) {
              clearInterval(intervalId);
              if (!$('#fp-pre-consent').is(':checked')) {
                IDPButtons.attr('disabled', 'disabled');
              }
            }
          }, 1);*/
        },
      },
    };
    this.firebaseUi = new firebaseui.auth.AuthUI(firebase.auth());
  }

  /**
   * Displays the signed-in user information in the UI or hides it and displays the
   * "Sign-In" button if the user isn't signed-in.
   */
  async onAuthStateChanged(user) {    
    //************************************************************ */
    // Reload the page unless this is the first time being loaded and no signed-in user.
    if (this._waitForAuthPromiseResolver.state() !== 'pending' || user) {        
        Router.reloadPage();
    }

    this.validaReload()

    const div_install = document.querySelector('#div-install');
    if (localStorage.isPwaInstalled === "true" || localStorage.standaloneDetected === "true") {
      div_install.style.display = 'none';   
    }

    this._waitForAuthPromiseResolver.resolve();
    if (!user) {
      this.userId = null;
      this.firebaseUi.start('#firebaseui-auth-container', this.uiConfig);
      //this.firebaseUi.disableAutoSignIn();      
      let logoBreathing = document.getElementsByClassName('page-splash_breathing');
      for (let i = 0; i < logoBreathing.length; i++) {
        logoBreathing[i].className = 'page-splash_img'
      }
    } else {
      if (!sessionStorage.chave || sessionStorage.chave === "undefined" || sessionStorage.chave === '' || sessionStorage.chave === '[object Promise]') {
          sessionStorage.chave = await this.firebaseHelper.getUsuarioChavePrincipal(user.uid)
          sessionStorage.isRP = sessionStorage.chave.indexOf('RP-') >= 0 ? 'true' : 'false'
      }
      this.userId = user.uid;
      this.usernameLink.attr('href', `/user/${user.uid}`);   
      firebase.analytics().logEvent('login')
    }
  }

  validaReload() {
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      sessionStorage.isReloading = "true"
    } else {
      sessionStorage.isReloading = "false"
    }
  }
};
