'use strict';

import FirebaseHelper from "./FirebaseHelper";

export default class LoadImage {
  constructor() {
  }

  carregarImagemAppEspecifica(imagens, imgNome) {
    let imagem = imagens.find((i) => {
      return i.nome === imgNome
    })
    return imagem
  }
  
  async carregarImagemApp(nome) {
    let imagens
    if (!sessionStorage.settings_Imagens) {
      imagens = await this.getImagensManifesto()
      sessionStorage.settings_Imagens = JSON.stringify(imagens)
    } else {
      imagens = JSON.parse(sessionStorage.settings_Imagens)
    }
    return this.carregarImagemAppEspecifica(imagens, nome)
  }

  async getImagensManifesto() {
    let a = document.getElementById("manifest-link"); 
    return fetch(a.href)
      .then(res => {
        return res.json()
      }).then((ret) => {
        return ret.images
      })
  }

}
