//'use strict';
import "./app.css";
import $ from "jquery";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import firebaseConfig from "./firebase-config.json";
import FirebaseHelper from "./FirebaseHelper";
import Auth from "./Auth";
import Router from "./Router";
import LoadImage from './LoadImage';
import "material-design-lite";

// Styling
import "material-design-icons/iconfont/material-icons.css";
import "typeface-amaranth/index.css";
import "material-design-lite/material.min.css";
import "mdl-ext/lib/mdl-ext.min.css";

const install_button = document.querySelector("#bt-install");
const abrir_app_btn_win = document.querySelector("#button-abrirapp-windows");
const abrir_app_btn_mob = document.querySelector("#button-abrirapp-mobile");
const div_abrirapp = document.querySelector("#div-abrirapp");
const div_continuarInstal = document.querySelector("#div-continuarInstal");
const divPrevdigi = document.querySelector("#div-prevdigi");
const div_install = document.querySelector("#div-install");
const msgInstalacao = document.querySelector("#msg-instalacao");
const msgInicial = document.querySelector("#msg-inicial");
const msgSplashLogin = document.querySelector("#msg-splash-login");
const pageInstall = window.location.href.indexOf("/instalar") > 0;
let pageReset = window.location.href.indexOf("/reset") > 0;

let ultimoTipoTela = 0;
let base_spinner_install = document.querySelector("#base_spinner_install");
let count_monta_tela = 0;
let beforeInstallPromptOK
let manifest

const loadImage = new LoadImage()

//let base_spinner_update = document.querySelector('#base_spinner_update')

const checkIfIsIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
  //return true
};
const checkIfIsMac = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /macintosh/.test(userAgent);
  //return false
};
const checkIfIsSamsungBrowser = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /samsungbrowser/.test(userAgent);
  //return false
};
const checkIfWindows = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /windows/.test(userAgent);
  //return false
};
const checkIfIsPwaInstalled = isIos => {
  if (isIos === "false") {
    var displayModes = ["fullscreen", "standalone", "minimal-ui"];
    let installed =
      displayModes.some(
        displayMode =>
          window.matchMedia("(display-mode: " + displayMode + ")").matches
      ) || window.navigator.standalone === true;
    return installed;
  } else {
    return "standalone" in window.navigator && window.navigator.standalone;
  }
};

//------------------------------------------------------------------------------------------------------
// BLOCO: Instalação do APP - Android
beforeInstallPromptOK = false;

if (pageInstall && !checkIfIsIos()) {
  //seta evento before install prompt - somente para Android e Windows
  window.deferredPrompt = {};
  window.addEventListener("beforeinstallprompt", e => {
    beforeInstallPromptOK = true;
    localStorage.isPwaInstalled = "false"; ///se o Browser aceitou o beforeinstallprompt certeza que o PWA não está instalado...
    // prevent default event
    e.preventDefault();
    // store install avaliable event
    window.deferredPrompt = e;
    montaTelaInstalacao(2);
  });

  //seta click listener para o botão de instalação
  install_button.addEventListener("click", e => {
    if (!window.deferredPrompt) {
      return;
    }
    try {
      window.deferredPrompt.prompt().then(() => {
        window.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            base_spinner_install.style.display = "block";
            setTimeout(() => {
              montaTelaInstalacao(4);
            }, 4000);
          } else {
            //não aceitou a instalação...
            localStorage.isPwaInstalled = "false";
            resetaAppInfo();
          }
          window.deferredPrompt = null;
        });
      });
    } catch (e) {
      //se erro ao tentar abrir prompt... reset dados do app e tenta novamente
      resetaAppInfo().then(() => {
        return montaApp();
      });
    }
  });
}


// Register the Service Worker that enables offline.
if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  $(window).on("load", async () => {
    const splash = document.querySelector("#page-splash-login");
    splash.style.display="flex"
    registraECarrega();
  });
}

async function registraECarrega() {
  let inicializou = await inicializaFirebase();
  if (inicializou === null) {
    return
  }

  //get manifesto e demais variáveis gerais do App
  manifest = await validaAmbienteManifesto();
  //let manifestVersao = manifest.manifest_version
  let imagemLoadApp = loadImage.carregarImagemAppEspecifica(manifest.images, "manifest-splash-512")
  let icon = loadImage.carregarImagemAppEspecifica(manifest.images, "favicon")
  let background = loadImage.carregarImagemAppEspecifica(manifest.images, "background-color")

  let img = document.getElementById("img-splash-login")
  img.setAttribute("src", imagemLoadApp.url);
  img.setAttribute("class", "page-splash_img page-splash_breathing");
  document.getElementById('icon-previdencia-digital').href = icon.url
  document.getElementById("page-splash-login").style.background = background.url

  let p0 = carregarTermoAceite()
  let p1 = carregarVersaoBD()
  let p2 = carregaUltimaChaveParticipante() //incluido por Leandro: para acelerar o processo de load da home do participante

  let promises = await Promise.all([p0, p1, p2])
  let termo = promises[0]
  let versaoBD = promises[1]
  document.getElementById('a-termo-confidencialidade').href = termo.url
  document.getElementById("label-termo-aceite-app").innerText = termo.texto

  let tipoInicializa = validaVersaoApp(versaoBD);
  let versao = localStorage.versao; //salva a versão para restaurar depois do reset cache
  window.navigator.serviceWorker.register("/workbox-sw.js").then((registration) => {
    let p = new Promise(resolve => {
      if (pageReset || tipoInicializa === 1) {
        localStorage.isPwaInstalled = ""
        if (pageReset) {
          location.pathname = '/home' 
        }
        pageReset = false        
        //reseta o App para atualizar o cache!
        resetaAppInfo().then(() => {
          localStorage.versao = versao;
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
    return p.then((reload) => {
      if (reload) {
        location.reload(true)
      } else {
        document.getElementById("firebaseui-auth-container").style.display = "flex";
        montaApp();
      }
    });
  });
}

function detectStandalone() {
  const hash = window.location.hash;
  let standalone = false;
  if (
    hash.indexOf("#:standalone:") >= 0 ||
    window.matchMedia("(display-mode: standalone)").matches
  ) {
    standalone = true;
    sessionStorage.setItem(":standalone:", "true");
    history.replaceState(history.state, "", "/");
  } else if (sessionStorage.getItem(":standalone:") === "true") {
    standalone = true;
  }

  //Estratégia para Samsung Browser...
  if (!standalone && checkIfIsSamsungBrowser()) {
    standalone = window.innerHeight / window.screen.height > 0.9;
  }
  return standalone;
}

function mostraTelaInstalacaoAndroidWindows() {
  let imagem = loadImage.carregarImagemAppEspecifica(manifest.images, "Component 54 ÔÇô 2")
  let img = document.getElementById("img-instalacao")
  img.setAttribute("src", imagem.url);
  div_install.style.display = "block";
  divPrevdigi.style.display = "block";
  $("#page-instalacao-ios").hide();
  $("#page-instalacao").show();
  return true
}
function mostraTelaInstalacaoIOS() {
  let imagem = loadImage.carregarImagemAppEspecifica(manifest.images, "imgAplleInstall")
  let img2 = document.getElementById("img-msg-instalacao")
  img2.setAttribute("src", imagem.url);

  imagem = loadImage.carregarImagemAppEspecifica(manifest.images, "Component 54 ÔÇô 2")
  let img = document.getElementById("img-instalacao-ios")
  img.setAttribute("src", imagem.url);
  $("#page-instalacao-ios").show();
  $("#page-instalacao").hide();
}

async function montaApp() {
  localStorage.isIos = checkIfIsIos();
  localStorage.isMac = checkIfIsMac();
  localStorage.isSamsungBrowser = checkIfIsSamsungBrowser();
  localStorage.standaloneDetected = detectStandalone();
  if (
    !localStorage.isPwaInstalled ||
    localStorage.isPwaInstalled === "false" ||
    localStorage.isPwaInstalled === ""
  ) {
    localStorage.isPwaInstalled = checkIfIsPwaInstalled(localStorage.isIos);
  }
  //corrige a rota quando está abrindo pelo App (standalone) e ainda está em rota de instalação
  if (
    localStorage.isPwaInstalled === "true" &&
    localStorage.standaloneDetected === "true" &&
    pageInstall
  ) {
    window.location.replace("./");
  } else {
    //seta evento para verificar quando App instalado
    if (pageInstall) {
      window.addEventListener("appinstalled", e => {
        localStorage.isPwaInstalled = "true";
        base_spinner_install.style.display = "block";
        setTimeout(() => {
          montaTelaInstalacao(5);
        }, 20000);
      });
    }

    // Load the app.
    $(document).ready(async() => {
      sessionStorage.nomeProjeto = firebaseConfig.result.sdkConfig.projectId;
      if (
        localStorage.isMac === "true" ||
        (localStorage.isPwaInstalled === "true" &&
          localStorage.standaloneDetected === "true")
      ) {
        const auth = new Auth();
        // Starts the router.
        window.fpRouter = new Router(auth);
      } else {
        if (localStorage.isIos === "false") {
          mostraTelaInstalacaoAndroidWindows();
          montaTelaInstalacao(0).then(() => {
            if (localStorage.isPwaInstalled === "true") {
              if (
                localStorage.standaloneDetected === "false" ||
                localStorage.standaloneDetected === ""
              ) {
                localStorage.standaloneDetected = detectStandalone();
              }
              if (
                localStorage.standaloneDetected === "false" ||
                localStorage.standaloneDetected === ""
              ) {
                //já instalou e está acessando novamente pela URL
                montaTelaInstalacao(1);
              }
            } else if (!beforeInstallPromptOK) {
              localStorage.isPwaInstalled = "true";
              montaTelaInstalacao(1);
            }
          });
        } else {
          mostraTelaInstalacaoIOS();
        }
      }
    });

    // Initialize Google Analytics.
    import(/* webpackPrefetch: true */ "universal-ga").then(analytics => {
      analytics.initialize("UA-25993200-10");
      analytics.pageview("/");
    });

    // Start the offline indicator listener.
    //Utils.startOfflineListener();
  }
}

function resetaAppInfo() {
  //limpa todos os storages
  localStorage.clear();
  sessionStorage.clear();
  //limpa cookies se houver
  var cookies = document.cookie;
  for (var i = 0; i < cookies.split(";").length; ++i) {
    var myCookie = cookies[i];
    if (myCookie !== undefined) {
      var pos = myCookie.indexOf("=");
      var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  let p0 = new Promise(resolve => {
    //limpa o cache
    self.caches.keys().then(keys => {
      keys.forEach(key => {
        self.caches.delete(key);
      });
      resolve(true);
    });
  });
  let p1 = new Promise(resolve => {
    return navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        registration.unregister().then(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  });
  return Promise.all([p0, p1]).then(retPromises => {

    if (window.location.href.indexOf("/reset") > 0) {
      window.location.replace("./");
    }
    return retPromises[1];
  });
}

function validaVersaoApp(versaoBD) {
  if (!localStorage.versao) {
    localStorage.versao = versaoBD;
    return 1;
  } else {
    //!pageInstall &&
    if (localStorage.versao !== versaoBD) {
      localStorage.versao = versaoBD;
      return 1;
    } else {
      return 0;
    }
  }
}

async function validaAmbienteManifesto() {
  let a = document.getElementById("manifest-link");
  let project = firebaseConfig.result.sdkConfig.projectId;
  if (project.indexOf("-dev") >= 0 || project.indexOf("-teste") >= 0) {
    a.href = "manifest-dev.json";
  } else if (project.indexOf("-hmg") >= 0 || project.indexOf("-hom") >= 0) {
    a.href = "manifest-hmg.json";
  } else {
    if (project.toLowerCase().indexOf("oabprevpr") >= 0) {
      a.href = "manifest-oabprevpr.json"
    } else {
      a.href = "manifest.json"
    }
  }

  return fetch(a.href)
    .then(res => {
      return res.json()
    }).then((ret) => {
      return ret
    })
}

async function carregaUltimaChaveParticipante() {
  let isRP = (sessionStorage || {}).isRP==="true"
  //só vai pré-carregar a última chave se não for um RP e o login atual for o mesmo que gravou a ultimaChave
  if (!isRP && !pageInstall && localStorage.ultimaChave && localStorage.ultimoUid === sessionStorage.uid) {
    let firebaseHelper = new FirebaseHelper();
    return firebaseHelper.getParticipantePorChave(localStorage.ultimaChave, sessionStorage.uid)
    .then((participante) => {
      if (participante) {
        sessionStorage.participante = JSON.stringify(participante)
      }
      return true
    })
  } else {
    localStorage.ultimoUid = ''
    localStorage.ultimaChave = ''
    sessionStorage.participante = ''
    return true
  }
}

async function inicializaFirebase() {
  // Configure Firebase.
  if (!window.navigator.onLine) {
    msgSplashLogin.innerHTML = "Puxa vida!<br><br>Sua internet pode ter caído ou estar instável :( <br> Feche o App e retorne quando estiver tudo ok!";
    return null
  }
  try {
    firebase.initializeApp(firebaseConfig.result.sdkConfig);
  } catch (e) {
    msgSplashLogin.innerHTML = "Puxa vida!<br><br>Houve um problema na inicialização do App :( <br> Por favor, aguarde alguns segundos e tente novamente!";
    return null
  }
  firebase.analytics();
  window.firebase = firebase;
  return true
}

function habilitaBotaoAcessarApp() {
  if (checkIfWindows()) {
    abrir_app_btn_win.style.display = "block";
    abrir_app_btn_mob.style.display = "none";
  } else {
    abrir_app_btn_win.style.display = "none";
    abrir_app_btn_mob.style.display = "block";
  }
}

async function montaTelaInstalacao(tipoTela) {

  count_monta_tela ++
  let timeout;
  if (tipoTela === 0 && tipoTela > ultimoTipoTela) {
    div_abrirapp.style.display = "none";
    div_continuarInstal.style.display = "none";
    //ret = barraProgressoIniciar(false, 40, 1)
    base_spinner_install.style.display = "block";
  } else if (tipoTela === 1 && tipoTela > ultimoTipoTela) {
    base_spinner_install.style.display = "block";
    timeout = setTimeout(() => {
      //barraProgressoIniciar(false, 0, 1) //desliga barra
      if (tipoTela >= ultimoTipoTela) {
        div_abrirapp.style.display = "block";
        div_continuarInstal.style.display = "none";
        install_button.style.display = "none";
        base_spinner_install.style.display = "none";
        msgInicial.innerHTML = "Seu App já está instalado!";
        if (checkIfWindows()) {
          msgInstalacao.innerHTML =
            "Por favor, efetue o acesso através do atalho criado na área de trabalho do seu computador.";
        } else if (checkIfIsSamsungBrowser()) {
          msgInstalacao.innerHTML =
            "Por favor, efetue o acesso através do ícone Mais Futuro disponível na sua lista de Apps, ou clique no ícone Mais Futuro acima.";
        } else {
          msgInstalacao.innerHTML = "Clique no botão abaixo para acessar.";
          habilitaBotaoAcessarApp();
        }
      }
    }, 8000);
  } else if (tipoTela === 2 && tipoTela > ultimoTipoTela) {
    if (timeout) {
      clearTimeout(timeout);
    }
    div_abrirapp.style.display = "none";
    div_continuarInstal.style.display = "none";
    base_spinner_install.style.display = "none";
    //barraProgressoIniciar(false, 0, 1) //desliga barra
    if (beforeInstallPromptOK) {
      install_button.style.display = "block";
      msgInicial.innerHTML = "";
      msgInstalacao.innerHTML = "Estamos prontos! Vamos lá?";
    }
  } else if (tipoTela === 4 && tipoTela > ultimoTipoTela) {
    install_button.style.display = "none";
    div_abrirapp.style.display = "none";
    div_continuarInstal.style.display = "none";
    msgInicial.innerHTML = "";
    msgInstalacao.innerHTML = "Aguarde. Estamos instalando o App. ";
    install_button.style.display = "none";
    divPrevdigi.style.display = "none";
    base_spinner_install.style.display = "block";
    //ret = barraProgressoIniciar(false, 100, 1)
  } else if (tipoTela === 5 && tipoTela > ultimoTipoTela) {
    if (checkIfIsSamsungBrowser()) {
      //infelizmente não dá launch no Samsung Browser
      if (timeout) {
        clearTimeout(timeout);
      }
      window.location.replace("./");
    } else {
      install_button.style.display = "none";
      div_continuarInstal.style.display = "none";
      div_abrirapp.style.display = "block";
      msgInicial.innerHTML = "Instalação concluída!";
      if (checkIfWindows()) {
        msgInstalacao.innerHTML = `A partir de agora acesse nosso portal através do atalho instalado na área de trabalho do seu computador.`;
      } else {
        msgInstalacao.innerHTML = `A partir de agora acesse nosso portal através do App instalado no seu dispositivo.`;
      }
      //barraProgressoIniciar(false, 0, 1) //desliga barra
      base_spinner_install.style.display = "none";
      habilitaBotaoAcessarApp();
    }
  } else if (tipoTela === 8) {
    //barraProgressoIniciar(false, 0, 1) //desliga barra
    base_spinner_install.style.display = "none";
    install_button.style.display = "none";
    div_abrirapp.style.display = "none";
    msgInicial.innerHTML = "Configurações resetadas!";
    msgInstalacao.innerHTML = `Clique abaixo para prosseguir.`;
    div_continuarInstal.style.display = "block";
  } else if (tipoTela === 9) {
    //barraProgressoIniciar(false, 0, 1) //desliga barra
    base_spinner_install.style.display = "none";
    install_button.style.display = "none";
    div_continuarInstal.style.display = "none";
    div_abrirapp.style.display = "none";
    msgInicial.innerHTML = "Infelizmente algo deu errado.";
    msgInstalacao.innerHTML = `Identificamos um problema na instalação do seu App. Por favor entre em contato conosco pelo e-mail atendimento@maisfuturo.com.br`;
  }
  ultimoTipoTela = tipoTela;
  return;
}

async function carregarTermoAceite() {
  let termo
  if(sessionStorage.getItem('settings_Termo')) {
    termo = JSON.parse(sessionStorage.getItem('settings_Termo'))
  } else {
    let firebaseHelper = new FirebaseHelper();
    termo = await firebaseHelper.getTermoAceiteApp();
    sessionStorage.setItem('settings_Termo', JSON.stringify(termo))
  }
  return termo
}

async function carregarVersaoBD() {
  let firebaseHelper = new FirebaseHelper();
  return await firebaseHelper.getVersaoBD();
}
